import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.sticky.js";
import { Search } from '@element-plus/icons-vue';
import EditorS from '../../components/popup/EditorUeditorSector';
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "SectorContent",
  components: {
    EditorS: EditorS
  },
  data: function data() {
    return {
      filterTargetTypeDataList: [],
      //筛选后的数组
      //search图标
      Search: Search,
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // 筛选
      filter: [{
        placeholder: '板块内容状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '标题',
        value: '',
        type: 'text',
        list: []
      }],
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 10,
      //每页个数

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      dialogContenState: "add",
      isEditorShow: false,
      nowArticleData: {},
      modeType: 'article',
      ueidtorName: 'ueditor',
      val: '请输入内容',
      editorContent: '',
      dialogUrlVisible: false,
      dialogUrlState: "add",
      dialogUrlData: {
        sectionId: '',
        title: "",
        stage: "",
        url: "",
        rank: 1,
        state: 1,
        // isshow: 1,
        cover: ""
      }
    };
  },
  watch: {
    $route: function $route(newVal, oldVal) {
      this.changeRouter();
    }
  },
  crated: function crated() {},
  mounted: function mounted() {
    this.sectorGetAll();
  },
  methods: {
    //获取所有维度
    sectorGetAll: function sectorGetAll() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'sectorGetAll',
        data: {},
        sucFn: function sucFn(res) {
          _this2.treeData = res.data;
          // 最上级板块
          if (!_this2.$route.query.tree) {
            _this2.currentTree = _this2.treeData[0];
          } else {
            // 选中过板块
            _this2.currentTree = JSON.parse(_this2.$route.query.tree);
          }
          _this2.$nextTick(function () {
            _this2.$refs.tree.setCurrentKey(_this2.currentTree.id);
          });
          _this2.changeRouter();
        }
      });
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    // 初始化数据
    initData: function initData() {
      var _this3 = this;
      if (!this.currentTree.id) return;
      this.service.axiosUtils({
        requestName: "sectorContentGetList",
        data: {
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          title: this.filter[1].value,
          state: this.filter[0].value,
          sectionId: this.currentTree.id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.tableData = res.data.data;
            _this3.total = res.data.total;
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    //获取板块下全部板块内容
    handleNodeClick: function handleNodeClick(data) {
      this.currentTree = _objectSpread({}, data);
      delete this.currentTree.children;
      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    //筛选功能
    search: function search(filterArr) {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: "sectorContentGetList",
        data: {
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          sectionId: this.currentTree.id,
          title: this.filter[1].value,
          state: this.filter[0].value
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.tableData = res.data.data;
            _this4.total = res.data.total;
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.search();
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    // 添加图文内
    addContent: function addContent() {
      this.isEditorShow = true;
    },
    // 添加URL内容
    addUrl: function addUrl() {
      this.clearUrlData();
      this.dialogUrlData.sectionId = this.currentTree.id;
      this.dialogUrlState = "add";
      this.dialogUrlVisible = true;
    },
    //取消url弹框
    hideUrlPopup: function hideUrlPopup() {
      this.dialogUrlVisible = false;
      this.$refs["urlform"].resetFields();
      this.clearUrlData();
    },
    clearUrlData: function clearUrlData() {
      this.dialogUrlData = {
        sectionId: '',
        title: "",
        stage: "",
        url: "",
        rank: 1,
        state: 1,
        // isshow: 1,
        cover: "",
        type: 2
      };
    },
    //修改课程图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        _this.dialogUrlData.cover = this.result;
      };
      uploadFile({
        folderName: "section/cover",
        complete: function complete(res, url) {
          _this.dialogUrlData.cover = url;
          window.loading.close();
        }
      }, _this.file);
    },
    handleAddUrl: function handleAddUrl() {
      var _this5 = this;
      var that = this;
      this.$refs["urlform"].validate(function (valid) {
        if (valid) {
          that.dialogUrlData.userId = that.userInfo.userId;
          that.dialogUrlData.roleId = that.userInfo.roleId;
          // 处理下数据
          var requestName = _this5.dialogUrlState === 'add' ? 'addSectorContent' : 'editSectorContent';
          that.service.axiosUtils({
            requestName: requestName,
            data: that.dialogUrlData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.dialogUrlVisible = false;
                that.clearUrlData();
                that.currentPage = 1;
                that.$nextTick(function () {
                  this.$refs["urlform"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-中操作函数
    handleEdit: function handleEdit(row, type) {
      type === 2 ? this.editUrl(row) : this.editContent(row);
    },
    // 编辑图文内
    editContent: function editContent(row) {
      this.isEditorShow = true;
      this.nowArticleData = row;
    },
    // 编辑URL内容
    editUrl: function editUrl(row) {
      this.clearUrlData();
      this.dialogUrlData = _objectSpread(_objectSpread({}, row), {}, {
        sectionId: this.currentTree.id
      });
      this.dialogUrlState = "edit";
      this.dialogUrlVisible = true;
    },
    handleDelete: function handleDelete(val) {
      this.centerDialogVisible = true;
      this.delId = val.id;
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this6 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "deleteSectorContent",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.$toast.success(res.message);
            _this6.delId = "";
            _this6.centerDialogVisible = false;
            _this6.initData();
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // 修改置顶
    editSticky: function editSticky(row) {
      var _this7 = this;
      var rowSticky = "";
      if (row.sticky === 1) {
        rowSticky = 0;
      } else {
        rowSticky = 1;
      }
      this.service.axiosUtils({
        requestName: "editSectorContentSticky",
        data: {
          id: row.id,
          sticky: rowSticky
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.$toast.success(res.message);
            if (row.sticky === 1) row.sticky = 0;else row.sticky = 1;
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    // 修改正常/禁用状态
    editState: function editState(row) {
      var _this8 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "editSectorContentStatus",
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    }
  }
};